import styled, { StyledComponent } from "@emotion/styled"
import tw from "twin.macro"
import {css as cssImport} from "@emotion/react"

const Text: StyledComponent<any, any, any> & any = styled.span`
  ${tw`font-normal antialiased text-black-1`}
  ${({ css }: any) => css && cssImport(css)}
`

Text.title = styled(Text.withComponent("h1"))`
  ${tw`font-avenirBook text-black-1 text-3xl`}
  ${({ css }: any) => css && cssImport(css)}
`

Text.headline = styled(Text.withComponent("h2"))`
  ${tw`text-2xl`}
  ${({ css }: any) => css && cssImport(css)}
`

Text.subhead = styled(Text.withComponent("h2"))`
  ${tw`text-xl`}
  ${({ css }: any) => css && cssImport(css)}
`

Text.subtitle = styled(Text.withComponent("h2"))`
  ${tw`font-avenirBook text-21/16 text-black-1 `}
  ${({ css }: any) => css && cssImport(css)}
`

Text.medium = styled(Text.withComponent("h2"))`
  ${tw`font-avenirBook text-17/16 text-black-1 `}
  ${({ css }: any) => css && cssImport(css)}
`

Text.subTitleBold = styled(Text.withComponent("h2"))`
  ${tw`font-avenirHeavy text-21/16 text-black-1 `}
  ${({ css }: any) => css && cssImport(css)}
`

Text.body = styled(Text.withComponent("p"))`
  ${tw`text-15/16 text-black-15 font-avenirBook`}
  ${({ css }: any) => css && cssImport(css)}
`

Text.note = styled(Text.withComponent("p"))`
  ${tw`text-sm font-medium text-gray-26`}
  ${({ css }: any) => css && cssImport(css)}
`

const Error: StyledComponent<any, any, any> & any = styled.div`
  ${tw`text-red-600 font-avenirRoman`}
`

export const TextLinkBody = styled.span`
  ${tw`text-blue-25 text-15/16 font-avenirHeavy underline cursor-pointer`}
`

export const TextLink = styled.p`
  ${tw`text-blue-25 text-15/16 font-avenirHeavy hover:underline focus:underline cursor-pointer`}
`

export const TextWarnings = styled.span`
  ${tw`text-red-9 text-15/16 font-avenirBookOblique`}
`

export const TextCancelModal = styled.span`
  ${tw`text-gray-41 text-15/16 cursor-pointer font-avenirBook`}
`

Text.Error = Error

export default Text
